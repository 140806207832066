import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Settings } from '@salesforce/design-system-react';

import { AuthorizationProvider } from './context/authorization';
import { AccountProvider } from './context/account';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthorizationProvider>
      <AccountProvider>
        <App />
      </AccountProvider>
    </AuthorizationProvider>
  </React.StrictMode>
);

Settings.setAppElement('#root');
