import { Account, IAccountState } from '../types/account';

const userAccount: Account = {
  name: '',
  surname: '',
  companyName: '',
  position: '',
  industry: '',
  email: '',
  phone: '',
  isFleet: false,
  integrationsCount: 0
};

export const accountState: IAccountState = {
  user: userAccount,
  loading: false,
  errors: []
};
