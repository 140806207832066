import { FC } from 'react';
import { useStepsState } from '../../../../context/steps';

import {
  ProfileDetailsForm,
  PanelQnA
} from './components';

import { Grid, Label } from './styles';

const ProfileDetails: FC = () => {
  const { selectedStep } = useStepsState();

  return (
    <Grid>
      <Label>{selectedStep.label}</Label>
      <ProfileDetailsForm />
      <PanelQnA />
    </Grid>
  );
};

export default ProfileDetails;
