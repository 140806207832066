import { InputIcon } from '@salesforce/design-system-react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useUsersApi } from '../../../../../../context/users';
import useDebounce from '../../../../../../hooks/useDebounce';
import { SInput } from './styles';

const PlatformSearchBar: FC = () => {
  const [nameSearchValue, setNameSearchValue] = useState('');
  const debouncedSearchName = useDebounce<string>(nameSearchValue, 500);
  const [prevNameSearchValue, setPrevNameSearchValue] = useState('');

  const { dispatch, addFilter, clearFilter } = useUsersApi();

  const handleNameSearch: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    setNameSearchValue(e.target.value);
  };

  useEffect(() => {
    if (prevNameSearchValue !== '') {
      clearFilter(dispatch)({
        field: 'platforms',
        value: prevNameSearchValue,
        condition: 'includes'
      });
    }
    if (debouncedSearchName !== '') {
      addFilter(dispatch)({
        field: 'platforms',
        value: debouncedSearchName,
        condition: 'includes'
      });
      setPrevNameSearchValue(debouncedSearchName);
    }
  }, [debouncedSearchName]);

  return (
    <SInput
      iconLeft={
        <InputIcon
          assistiveText={{
            icon: 'Search'
          }}
          name="search"
          category="utility"
        />
      }
      id="search-input"
      placeholder="Filter by platform"
      value={nameSearchValue}
      onChange={handleNameSearch}
    />
  );
};

export default PlatformSearchBar;
