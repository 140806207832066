import { Button, Spinner } from '@salesforce/design-system-react';
import { FC, useEffect } from 'react';
import { useStepsApi, useStepsState } from '../../../../context/steps';
import { Integration } from '../../../../types/integrations';
import { IntegrationCheckbox } from './components';
import { Flex } from '../../../../styles';
import { CallsToAction, Container, Error, ErrorBox, Header, IntegrationsList, Label } from './styles';
import { useIntegrationsApi, useIntegrationsState } from '../../../../context/integrations';
import { useAuthorizationState } from '../../../../context/authorization';
import { useAccountApi, useAccountState } from '../../../../context/account';

const Integrations: FC = () => {
  const { loading: loadingIntegrations, integrations, selectedIntegrations, error } = useIntegrationsState();
  const { dispatch: integrationsDispatch, loadIntegrations, sendIntegrations } = useIntegrationsApi();
  const { selectedStep, steps } = useStepsState();
  const { dispatch: stepDispatch, setStep } = useStepsApi();
  const { authToken } = useAuthorizationState();
  const { loading: loadingUser, user } = useAccountState();
  const { dispatch: accountDispatch, getUser } = useAccountApi();

  useEffect(() => {
    getUser(accountDispatch)(authToken);
  }, []);

  useEffect(() => {
    if (user.integrationsCount < 1) {
      loadIntegrations(integrationsDispatch)(authToken);
    } else {
      setStep(stepDispatch)(steps[2]);
    }
  }, [user]);

  const handleOnSkip: () => void = () => {
    sendIntegrations(integrationsDispatch)(authToken, integrations.map(integration => integration.id));
  };

  const handleOnSubmit: () => void = () => {
    sendIntegrations(integrationsDispatch)(authToken, selectedIntegrations, () => { setStep(stepDispatch)(steps[2]); });
  };

  return (
    <Container>
      <Flex direction="column">
        <Label>{selectedStep.label}</Label>
        {
          loadingUser || loadingIntegrations
            ? <Spinner
              size="small"
              variant="base"
              assistiveText={{ label: 'Authorizing...' }}
              hasContainer
              containerStyle={{
                background: 'none',
                position: 'relative',
                marginTop: '200px'
              }}
            />
            : <>
              <Header>Choose platforms that you want users to be connected to: </Header>
              <ErrorBox><Error>{error}</Error></ErrorBox>
              <IntegrationsList>
                {
                  integrations
                    .map((integration: Integration) => <IntegrationCheckbox key={integration.id} integration={integration}/>)
                }
              </IntegrationsList>
              <CallsToAction>
                <Button
                  variant="outline-brand"
                  title="Skip"
                  label="Skip"
                  onClick={handleOnSkip}
                />
                <Button
                  variant="brand"
                  title="Go to next step"
                  label="Go to next step"
                  onClick={handleOnSubmit}
                  disabled={!(selectedIntegrations.length > 0)}
                />
              </CallsToAction>
            </>
        }
      </Flex>
    </Container>
  );
};

export default Integrations;
