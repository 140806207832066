import { Button, Checkbox, comboboxFilterAndLimit, Spinner } from '@salesforce/design-system-react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useAuthorizationApi } from '../../../../../../context/authorization';
import { useRegistrationApi, useRegistrationState } from '../../../../../../context/registration';
import { useStepsApi, useStepsState } from '../../../../../../context/steps';
import { industries } from '../../../../../../state/industries';

import { Flex } from '../../../../../../styles';
import { Industry } from '../../../../../../types/industries';
import { validateForm } from './functions';

import { AlignEnd, AlignStart, Container, GeneralError, Margin, SCombobox, SInput, TermsLink } from './styles';

const ProfileDetailsForm: FC = () => {
  const { steps } = useStepsState();
  const { dispatch: stepsDispatch, setStep } = useStepsApi();
  const { form, loading, errors } = useRegistrationState();
  const { dispatch: registrationDispatch, checkTerms, setField, registerUser } = useRegistrationApi();
  const { dispatch: authorizationDispatch } = useAuthorizationApi();

  const [isDisabled, setIsDisabled] = useState(true);
  const [secondPassword, setSecondPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [comboInput, setComboInput] = useState('');
  const [selection, setSelection] = useState<Industry[]>([]);

  useEffect(() => {
    setIsDisabled(!validateForm(form));
  }, [
    form,
    validateForm
  ]);

  useEffect(() => {
    if (form.password !== secondPassword) {
      setPasswordError('Passwords don\'t match.');
    } else {
      setPasswordError('');
    }
  }, [form.password, secondPassword]);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>): void => {
    checkTerms(registrationDispatch)(e.target.checked);
  };

  const submitRegistrationForm = (): void => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' })
        .then((token: string) => {
          registerUser(registrationDispatch)({ ...form, captcha: token }, authorizationDispatch, () => {
            setStep(stepsDispatch)(steps[1]);
          });
        });
    });
  };

  const handleOnChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, target: string) => void = (event, target) => {
    event.preventDefault();
    setField(registrationDispatch)(target, event.target.value);
  };

  const handleComboboxOnChange: (value: string) => void = (value) => {
    setField(registrationDispatch)('industry', value);
  };

  return (
    <>
      {
        (errors.find(error => error.path === 'general') != null)
          ? <GeneralError>{errors.find(error => error.path === 'general')?.message}</GeneralError>
          : null
      }
      <Container>
        {
          loading
            ? <Spinner
              size="small"
              variant="base"
              assistiveText={{ label: 'Authorizing...' }}
              hasContainer
              containerStyle={{
                background: 'none',
                position: 'relative',
                marginTop: '150px'
              }}
            />
            : <Flex direction="column" maxWidth={426}>
              <Flex justifyContent='space-between' gap={8}>
                <SInput
                  label="Name"
                  required
                  value={form.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'name')}
                  errorText={errors.find(error => error.path === 'name')}
                />
                <SInput
                  label="Surname"
                  required
                  value={form.surname}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'surname')}
                  errorText={errors.find(error => error.path === 'surname')}
                />
              </Flex>
              <SCombobox
                labels={{
                  label: 'Industry',
                  placeholder: 'Financial Services'
                }}
                variant="inline-listbox"
                required
                options={comboboxFilterAndLimit({
                  inputValue: comboInput,
                  options: industries,
                  selection
                })}
                selection={selection}
                value={comboInput}
                events={{
                  onChange: (event: any, { value }: { value: string }) => {
                    setComboInput(value);
                  },
                  onRequestRemoveSelectedOption: (event: any, data: any) => {
                    setComboInput('');
                    setSelection(data.selection);
                    handleComboboxOnChange('');
                  },
                  onSubmit: (event: any, { value }: { value: string }) => {
                    setComboInput('');
                    const newSelection = industries.find(industry => industry.label === value);
                    const update = newSelection !== undefined ? [newSelection] : [];
                    setSelection(update);
                  },
                  onSelect: (event: any, data: any) => {
                    setComboInput('');
                    setSelection(data.selection);
                    handleComboboxOnChange(data.selection[0].label);
                  }
                }}
              />
              <Margin />
              <SInput
                label="Company's name"
                required
                value={form.company}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'company')}
                errorText={errors.find(error => error.path === 'company')}
              />
              <SInput
                label="Job position"
                required
                value={form.position}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'position')}
                errorText={errors.find(error => error.path === 'position')}
              />
              <SInput
                label="Work email"
                required
                value={form.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'email')}
                errorText={errors.find(error => error.path === 'email')}
              />
              <Flex justifyContent='space-between' gap={8}>
                <SInput
                  label="Password"
                  type="password"
                  required
                  value={form.password}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'password')}
                  errorText={errors.find(error => error.path === 'password') ?? passwordError}
                />
                <SInput
                  label="Repeat password"
                  type="password"
                  required
                  value={secondPassword}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSecondPassword(e.target.value)}
                  errorText={passwordError}
                />
              </Flex>
              <AlignStart>
                <Flex>
                  <Checkbox
                    id="terms"
                    labels={{
                      label: 'I have read and accepted '
                    }}
                    value={form.termsAccepted}
                    onChange={handleCheck}
                    required
                  />
                  <TermsLink href="https://unveel.io" target="_blank">
                    Unveel Terms & Conditions
                  </TermsLink>
                </Flex>
              </AlignStart>
              <AlignEnd>
                <Button
                  variant="brand"
                  disabled={isDisabled}
                  onClick={submitRegistrationForm}
                >
                  Go to next step
                </Button>
              </AlignEnd>
            </Flex>
        }
      </Container>
    </>
  );
};

export default ProfileDetailsForm;
