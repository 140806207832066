import { DataTableRowActions, Dropdown } from '@salesforce/design-system-react';
import { FC, useState } from 'react';
import { useUsersState } from '../../../../context/users';
import { User } from '../../../../types/users';
import { columns } from './columns';
import { SDataTable } from './styles';
import { isEmpty } from 'lodash';

const UsersTable: FC = () => {
  const [selection, setSelection] = useState<User>();

  const handleChanged: (e: any, d: any) => void = (event, data) => {
    setSelection(data.selection);
  };

  const { users, filteredUsers, filters } = useUsersState();

  return (
    <SDataTable
      items={!isEmpty(filteredUsers) || !isEmpty(filters) ? filteredUsers : users}
      selection={selection}
      onRowChange={handleChanged}
      selectRows="checkbox"
      id="users-list-table">
      {columns}
      <DataTableRowActions
        key="options"
        options={[
          {
            id: 0,
            label: 'Freeze user',
            value: '1'
          },
          {
            id: 1,
            label: 'Delete user',
            value: '2'
          },
          {
            id: 2,
            label: 'Export .csv',
            value: '3'
          }
        ]}
        onAction={(item: User, action: any) => { console.log(item, action); }}
        dropdown={<Dropdown length={null} />}
      />
    </SDataTable>
  );
};

export default UsersTable;
