/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from 'styled-components';

import { FlexProps } from './types';

export const Flex = styled.div<FlexProps>`
    display: flex;
    ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
    align-items: center;
    ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
    ${props => props.direction && `flex-direction: ${props.direction};`}
    ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`}
    ${props => props.gap && `gap: ${props.gap}px;`}
    color: ${props => props.theme.palette.common.black};
`;
