import { FETCH_ACTIONS } from '../constants/action-types';

import { Account, IAccountState, IFetchAccountAction } from '../types/account';

const accountReducer = (state: IAccountState, action: IFetchAccountAction): IAccountState => {
  switch (action.type) {
    case FETCH_ACTIONS.FETCH_START:
      return {
        ...state,
        loading: true,
        errors: []
      };
    case FETCH_ACTIONS.FETCH_SUCCESS:
      return {
        user: action.payload as Account,
        loading: false,
        errors: []
      };
    case FETCH_ACTIONS.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        errors: action.payload ? [...state.errors, action.payload as string] : state.errors
      };
    default:
      return {
        ...state
      };
  }
};

export default accountReducer;
