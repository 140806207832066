export const getIntegrations: (authToken: string) => Promise<unknown> = async (authToken) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL as string}/list/integrations`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    await response.json();
    return response.ok
      ? await Promise.resolve(response.body)
      : await Promise.reject(new Error('Error occured during fetch of integrations.'));
  } catch (error: unknown) {
    return await Promise.reject(error);
  }
};

export const sendSelectedIntegrations: (authToken: string, data: string[]) => Promise<unknown> = async (authToken, selectedIntegrations) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL as string}/account/integrations`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(selectedIntegrations)
      }
    );
    await response.json();
    return response.ok
      ? await Promise.resolve(response.ok)
      : await Promise.reject(new Error('Error occured during registration.'));
  } catch (error: unknown) {
    return await Promise.reject(error);
  }
};
