import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Description1, Description2, Label, Image, Container, SButton } from './styles';
import { Flex } from '../../../../styles';

import TinyPeople from '../../../../assets/tiny_people_timesand.svg';

const Finish: FC = () => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" justifyContent="center">
      <Container>
        <Image
          src={TinyPeople}
        />
        <Label>Congratulations!</Label>
        <Description1>{'Now, we will tailor Unveel Panel according to your needs, for you to get the best experience out of it. When we\'re done, you\'ll get an email confirmation that Panel is ready to be explored.'}</Description1>
        <Description2>It usually takes us up to 24 hours.</Description2>
        <SButton
          variant="brand"
          title="Go to Unveel.io"
          label="Go to Unveel.io"
          onClick={() => { navigate('/'); }}
        />
      </Container>
    </Flex>
  );
};

export default Finish;
