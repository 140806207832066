import { createContext, FC, ReactNode, useReducer, useMemo, useContext } from 'react';

import { IAuthorizationApiContext, IAuthorizationState } from '../types/authorization';

import { authorizationActions } from '../actions';

import authorizationReducer from '../reducers/authorization';

import { authorization } from '../state/authorization';

const AuthorizationStateContext = createContext<IAuthorizationState>(authorization);
const AuthorizationApiContext = createContext<IAuthorizationApiContext | null>(null);

export const AuthorizationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(authorizationReducer, authorization);

  const api = useMemo(
    () => ({
      dispatch,
      authorizeUser: authorizationActions.authorizeUser,
      logoutUser: authorizationActions.logoutUser,
      refreshToken: authorizationActions.refreshToken,
      setField: authorizationActions.setField
    }),
    [dispatch, authorizationActions]
  );

  return (
    <AuthorizationStateContext.Provider value={state}>
      <AuthorizationApiContext.Provider value={api}>
        {children}
      </AuthorizationApiContext.Provider>
    </AuthorizationStateContext.Provider>
  );
};

export const useAuthorizationState: () => IAuthorizationState = () => {
  const ctx = useContext(AuthorizationStateContext);

  return ctx;
};

export const useAuthorizationApi: () => IAuthorizationApiContext = () => {
  const ctx = useContext(AuthorizationApiContext);

  if (ctx === null) {
    throw new Error('Authorization Api Context must be used within the Authorization Provider');
  }

  return ctx;
};
