import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1080px; 
  margin: auto;
  padding: 0 2rem;
`;

export const Label = styled.h2`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  align-self: start;
`;

export const Header = styled.h3`
  margin-top: calc(4rem/3);
  align-self: start;
  font-weight: 500;
  font-size: calc(5rem/3);
  line-height: calc(25rem/12);
  color: ${props => props.theme.palette.common.dark_grey};
`;

export const IntegrationsList = styled.div`
  margin-bottom: 8rem;
  height: 366px;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  display: grid;
  grid-column-gap: 110px;
  grid-row-gap: 80px;
  grid-template-columns: repeat(auto-fit, 90px);
  gird-auto-rows: 142px;
  flex-wrap: wrap;
  overflow: scroll;
  justify-content: space-between;
  align-items: flex-start;
  ::-webkit-scrollbar {
    display: none;
 }
`;

export const CallsToAction = styled.div`
  align-self: flex-end;
  display: flex;
  gap: calc(10rem/3);
`;

export const ErrorBox = styled.div`
  height: 8rem;
  width: 100%;
  display: grid;
  color: red;
`;

export const Error = styled.p`
  place-self: center;
`;
