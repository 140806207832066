import { isEmpty, isEqual } from 'lodash';
import { FETCH_ACTIONS, USERS_ACTIONS } from '../constants/action-types';

import { IAction } from '../types/common';

import { Filter, IUserState, User } from '../types/users';

const applyFilters: (filters: Filter[], users: User[]) => User[] = (filters, users) => {
  return filters.reduce((acc, filter) => {
    if (filter.field === 'platforms') {
      return [...acc.filter(user => !isEmpty(user.platforms.filter(platform => platform === filter.value)))];
    }
    switch (filter.condition) {
      case 'equal':
        return [...acc.filter(user => user[filter.field] === filter.value)];
      case 'notEqual':
        return [...acc.filter(user => user[filter.field] !== filter.value)];
      case 'bigger':
        return [...acc.filter(user => user[filter.field] > filter.value)];
      case 'smaller':
        return [...acc.filter(user => user[filter.field] < filter.value)];
      case 'includes':
        if (filter.value === '') {
          return [...acc];
        }
        // eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
        return [...acc.filter(user => user[filter.field].toLowerCase().includes(filter.value))];
      default:
        return [...acc];
    }
  }, users);
};

const usersReducer = (state: IUserState, action: IAction): IUserState => {
  let newFilters;
  switch (action.type) {
    case FETCH_ACTIONS.FETCH_START:
      return {
        users: [],
        filters: [],
        filteredUsers: [],
        loading: true,
        error: ''
      };
    case FETCH_ACTIONS.FETCH_SUCCESS:
      return {
        loading: false,
        error: '',
        users: action.payload as User[],
        filters: [],
        filteredUsers: []
      };
    case FETCH_ACTIONS.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload as string
      };
    case USERS_ACTIONS.ADD_FILTER:
      newFilters = [...(state.filters), action.payload as Filter];
      return {
        ...state,
        filters: newFilters,
        filteredUsers: applyFilters(newFilters, state.users)
      };
    case USERS_ACTIONS.CLEAR_FILTER:
      newFilters = [...(state.filters).filter(filter => !isEqual(filter, action.payload))];
      return {
        ...state,
        filters: newFilters,
        filteredUsers: applyFilters(newFilters, state.users)
      };
    case USERS_ACTIONS.CLEAR_FILTERS:
      return {
        ...state,
        filters: [],
        filteredUsers: []
      };
    default:
      return {
        ...state
      };
  }
};

export default usersReducer;
