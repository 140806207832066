export const getAccount: (authToken: string) => Promise<unknown> = async (authToken) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL as string}/account`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    await response.json();
    return response.ok
      ? await Promise.resolve(response.body)
      : await Promise.reject(new Error('Error occured during fetch of account.'));
  } catch (error: unknown) {
    return await Promise.reject(error);
  }
};
