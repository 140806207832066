import { FC, ReactNode } from 'react';

import { DataTableCell } from '@salesforce/design-system-react';
import { User } from '../../../../../../types/users';
import { isEmpty } from 'lodash';

interface CustomDataTableCellProps {
  children?: ReactNode
  item?: User
}

const CustomDataTableCell: FC<CustomDataTableCellProps> = ({ children, item, ...props }) => {
  return (
    <DataTableCell {...props}>
      {
        !isEmpty(item?.lastWorkdayIncome)
          ? new Intl.NumberFormat(
            'en-US',
            {
              style: 'currency',
              currency: item?.lastWorkdayIncome[0].currency
            }
          ).format(item?.lastWorkdayIncome[0].value as number)
          : ' '
      }
    </DataTableCell>
  );
};

CustomDataTableCell.displayName = DataTableCell.displayName;

export {
  CustomDataTableCell as DailyIncomeCell
};
