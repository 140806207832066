import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  margin: 0 calc(31rem /6);
  grid-template-columns: minmax(100%, 1240px);
  grid-template-rows: 6rem 6.5rem calc(37rem / 6) auto;
`;

export const Header = styled.h2`
  grid-row: 1;
  font-weight: 700;
  font-size: calc(7rem / 6);
  line-height: calc(7rem / 4);
  align-self: end;
`;
