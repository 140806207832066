import { Industry } from '../types/industries';

export const industries: Industry[] = [
  {
    id: 'Information Technology & Services',
    label: 'Information Technology & Services'
  },
  {
    id: 'Financial Services',
    label: 'Financial Services'
  },
  {
    id: 'Transport Industry',
    label: 'Transport Industry'
  },
  {
    id: 'Accounting',
    label: 'Accounting'
  },
  {
    id: 'Human Resource',
    label: 'Human Resource'
  },
  {
    id: 'Management',
    label: 'Management'
  },
  {
    id: 'Other',
    label: 'Other'
  }
];
