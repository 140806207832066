import { FC, ReactNode } from 'react';

import { Avatar, DataTableCell } from '@salesforce/design-system-react';

import { User } from '../../../../../../types/users';

import Active from '../../../../../../assets/active.svg';
import Pending from '../../../../../../assets/pending.svg';
import Inactive from '../../../../../../assets/inactive.svg';

interface CustomDataTableCellProps {
  children?: ReactNode
  item?: User
}

const CustomDataTableCell: FC<CustomDataTableCellProps> = ({ children, item, ...props }) => {
  return (
    <DataTableCell {...props}>
      <Avatar
        imgSrc={item?.status === 'active' ? Active : item?.status === 'pending' ? Pending : Inactive}
        imgAlt={item?.status}
        assistiveText={{ icon: 'status icon' }}
        variant='entity'
        title={item?.status}
      />
    </DataTableCell>
  );
};

CustomDataTableCell.displayName = DataTableCell.displayName;

export {
  CustomDataTableCell as StatusCell
};
