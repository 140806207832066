import { FC, ReactNode, useEffect, useState } from 'react';

import { DataTableCell } from '@salesforce/design-system-react';
import { User } from '../../../../../../types/users';

interface CustomDataTableCellProps {
  children?: ReactNode
  item?: User
}

const CustomDataTableCell: FC<CustomDataTableCellProps> = ({ children, item, ...props }) => {
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    let result = ' ';
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const lastSync = item?.lastSyncAt ? new Date(item.lastSyncAt.date) : null;
    if (lastSync !== null) {
      const differenceInTime = now.getTime() - lastSync.getTime();
      const differenceInHours = differenceInTime / (1000 * 3600);
      const differenceInDays = differenceInHours / 24;
      const daysString = `${Math.floor(differenceInDays)} day${Math.floor(differenceInDays) > 1 ? 's' : ''} ago`;
      const hoursString = `${Math.floor(differenceInHours)} hours${Math.floor(differenceInHours) > 1 ? 's' : ''} ago`;
      result = differenceInDays > 0 ? daysString : hoursString;
    }
    setDisplayValue(result);
  }, [item]);

  return (
    <DataTableCell {...props}>{displayValue}</DataTableCell>
  );
};

CustomDataTableCell.displayName = DataTableCell.displayName;

export {
  CustomDataTableCell as LastSyncCell
};
