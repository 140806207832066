import { FC, ReactNode } from 'react';

import { Avatar, DataTableCell } from '@salesforce/design-system-react';
import { User } from '../../../../../../types/users';
import { Container } from './styles';

interface CustomDataTableCellProps {
  children?: ReactNode
  item?: User
}

const CustomDataTableCell: FC<CustomDataTableCellProps> = ({ children, item, ...props }) => {
  return (
    <DataTableCell {...props}>
      <Container>
        {item?.platforms.map(platform => (
          <Avatar
            key={platform}
            imgSrc={`https://auth.unveel.io/assets/img/partner/${platform}.png`}
            imgAlt={platform}
            assistiveText={{ icon: 'platform icon' }}
            variant='entity'
            title={platform}
          />
        ))}
      </Container>
    </DataTableCell>
  );
};

CustomDataTableCell.displayName = DataTableCell.displayName;

export {
  CustomDataTableCell as PlatformsCell
};
