import { FETCH_ACTIONS, INTEGRATIONS_ACTIONS } from '../constants/action-types';
import { IAction } from '../types/common';
import { IIntegrationsState, Integration } from '../types/integrations';

const integrationsReducer = (state: IIntegrationsState, action: IAction): IIntegrationsState => {
  switch (action.type) {
    case FETCH_ACTIONS.FETCH_START:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case FETCH_ACTIONS.FETCH_SUCCESS:
      return {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        integrations: action.payload ? action.payload as Integration[] : state.integrations,
        selectedIntegrations: [],
        loading: false,
        error: ''
      };
    case FETCH_ACTIONS.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        error: action.payload as string
      };
    case INTEGRATIONS_ACTIONS.SELECT_INTEGRATION:
      return {
        ...state,
        selectedIntegrations: [...state.selectedIntegrations, action.payload as string]
      };
    case INTEGRATIONS_ACTIONS.UNSELECT_INTEGRATION:
      return {
        ...state,
        selectedIntegrations: [...state.selectedIntegrations.filter(id => id !== action.payload)]
      };
    default:
      return {
        ...state
      };
  }
};

export default integrationsReducer;
