import { FC, ReactNode } from 'react';

import { DataTableCell } from '@salesforce/design-system-react';
import { User } from '../../../../../../types/users';

interface CustomDataTableCellProps {
  children?: ReactNode
  item?: User
}

const CustomDataTableCell: FC<CustomDataTableCellProps> = ({ children, item, ...props }) => {
  return (
    <DataTableCell {...props}>
      {(new Date(item?.addedAt.date ?? '')).toLocaleDateString('en-UK', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })}
    </DataTableCell>
  );
};

CustomDataTableCell.displayName = DataTableCell.displayName;

export {
  CustomDataTableCell as DateCell
};
