import { Finish, Integrations, ProfileDetails } from '../containers/Register/components';

import { IStepsState, Step } from '../types/steps';

const steps: Step[] = [
  {
    id: 0,
    label: 'Profile Details',
    assistiveText: 'Profile Details',
    component: <ProfileDetails />
  },
  {
    id: 1,
    label: 'Integrations',
    assistiveText: 'Integrations',
    component: <Integrations />
  },
  {
    id: 2,
    label: 'Finish',
    assistiveText: 'Finish',
    component: <Finish />
  }
];

export const stepsState: IStepsState = {
  steps,
  completedSteps: [],
  disabledSteps: steps.slice(1),
  selectedStep: steps[0]
};
