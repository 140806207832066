import { IAuthorizationForm } from '../types/authorization';

export const sendLoginForm: (data: IAuthorizationForm) => Promise<unknown> = async (loginData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL as string}/authorize`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      }
    );
    await response.json();
    switch (response.status) {
      case 200:
        return await Promise.resolve(response.body);
      case 401:
      case 403:
        return await Promise.reject(response.body);
      default:
        return await Promise.reject(new Error('Error occured during authorization.'));
    }
  } catch (error: unknown) {
    return await Promise.reject(error);
  }
};

export const sendRefreshToken: (refreshToken: string) => Promise<unknown> = async (refreshToken) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL as string}/refresh-token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(refreshToken)
      }
    );
    await response.json();
    return response.ok
      ? await Promise.resolve(response.body)
      : await Promise.reject(new Error('Error occured during token refresh.'));
  } catch (error: unknown) {
    return await Promise.reject(error);
  }
};
