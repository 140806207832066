import { createContext, FC, ReactNode, useContext, useMemo, useReducer } from 'react';

import { accountActions } from '../actions';

import { IAccountApiContext, IAccountState } from '../types/account';

import { accountState } from '../state/account';

import accountReducer from '../reducers/account';

const AccountStateContext = createContext<IAccountState>(accountState);
const AccountApiContext = createContext<IAccountApiContext | null>(null);

export const AccountProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, accountState);

  const api = useMemo(
    () => ({
      dispatch,
      getUser: accountActions.getUser
    }),
    [dispatch, accountActions]
  );

  return (
    <AccountStateContext.Provider value={state}>
      <AccountApiContext.Provider value={api}>
        {children}
      </AccountApiContext.Provider>
    </AccountStateContext.Provider>
  );
};

export const useAccountState: () => IAccountState = () => {
  const ctx = useContext(AccountStateContext);

  return ctx;
};

export const useAccountApi: () => IAccountApiContext = () => {
  const ctx = useContext(AccountApiContext);

  if (ctx === null) {
    throw new Error('AccountApiContext must be used within the AccountProvider');
  }

  return ctx;
};
