import { createContext, FC, ReactNode, useReducer, useMemo, useContext } from 'react';
import { usersActions } from '../actions';
import usersReducer from '../reducers/users';
import { usersState } from '../state/users';
import { IUsersApiContext, IUserState } from '../types/users';

const UsersStateContext = createContext<IUserState>(usersState);
const UsersApiContext = createContext<IUsersApiContext | null>(null);

export const UsersProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(usersReducer, usersState);

  const api = useMemo(
    () => ({
      dispatch,
      loadUsers: usersActions.loadUsers,
      addFilter: usersActions.addFilter,
      clearFilter: usersActions.clearFilter,
      clearFilters: usersActions.clearFilters
    }),
    [dispatch, usersActions]
  );

  return (
    <UsersStateContext.Provider value={state}>
      <UsersApiContext.Provider value={api}>
        {children}
      </UsersApiContext.Provider>
    </UsersStateContext.Provider>
  );
};

export const useUsersState: () => IUserState = () => {
  const ctx = useContext(UsersStateContext);

  return ctx;
};

export const useUsersApi: () => IUsersApiContext = () => {
  const ctx = useContext(UsersApiContext);

  if (ctx === null) {
    throw new Error('UsersApiContext must be used within the UsersProvider');
  }

  return ctx;
};
