import { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuthorizationState } from '../../context/authorization';

const Home: FC = () => {
  // eslint-disable-next-line
  const { authToken } = useAuthorizationState();

  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    navigate(`${authToken ? '/users' : '/login'}`);
  }, []);

  return (<></>);
};

export default Home;
