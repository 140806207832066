import { FC } from 'react';

import { FilterButtonsLevel, SearchBarLevel, UsersTable } from './components';

import { Container, Header } from './styles';

const UsersList: FC = () => {
  return (
    <Container>
      <Header>Users list</Header>
      <SearchBarLevel />
      <FilterButtonsLevel />
      <UsersTable />
    </Container>
  );
};

export default UsersList;
