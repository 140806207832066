import { FC } from 'react';
import { Flex } from '../../../../../../styles';

import {
  Answer,
  Container,
  Question,
  Tile,
  Title
} from './styles';

const PanelQnA: FC = () => {
  return (
    <Container>
      <Flex direction="column">
        <Title>Panel Q&A</Title>
        <Tile>
          <Question>{'Why do I have to provide this information?'}</Question>
          <Answer>{'We want to create you a profile according to your needs. Different users need different functionalities, tailored to their work style.'}</Answer>
        </Tile>
        <Tile>
          <Question>{'What if there\'s no industry for me to pick from?'}</Question>
          <Answer>{'Just choose \'Other\' and please specify next to the company\'s name.'}</Answer>
        </Tile>
        <Tile>
          <Question>{'What kind of password is required?'}</Question>
          <Answer>{'Use 8 or more characters with a mix of letters, numbers & symbols.'}</Answer>
        </Tile>
      </Flex>
    </Container>
  );
};

export default PanelQnA;
