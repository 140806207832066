import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  palette: {
    common: {
      black: '#3E3E3C',
      white: '#ffffff',
      dark_blue: '#16325C',
      light_blue: '#4285F4',
      greyish_blue: '#394A60',
      dark_grey: '#3A3F48',
      light_grey: '#455A76'
    }
  }
};
