import { FETCH_ACTIONS, INTEGRATIONS_ACTIONS } from '../constants/action-types';
import { getIntegrations, sendSelectedIntegrations } from '../queries/integrations';

import { LoadIntegrationsAction, SelectionAction, SendIntegrationsAction } from '../types/integrations';

export const loadIntegrations: LoadIntegrationsAction = dispatch => async (authToken) => {
  try {
    dispatch({ type: FETCH_ACTIONS.FETCH_START });
    const integrations = await getIntegrations(authToken);
    dispatch({ type: FETCH_ACTIONS.FETCH_SUCCESS, payload: integrations });
  } catch (error: unknown) {
    dispatch({ type: FETCH_ACTIONS.FETCH_ERROR, payload: (error as Error).message });
  }
};

export const sendIntegrations: SendIntegrationsAction = dispatch => async (authToken, selectedIntegrations, callback = () => {}) => {
  try {
    dispatch({ type: FETCH_ACTIONS.FETCH_START });
    await sendSelectedIntegrations(authToken, selectedIntegrations);
    dispatch({ type: FETCH_ACTIONS.FETCH_SUCCESS });
    callback();
  } catch (error: unknown) {
    dispatch({ type: FETCH_ACTIONS.FETCH_ERROR, payload: (error as Error).message });
  }
};

export const selectIntegration: SelectionAction = dispatch => (id) => {
  dispatch({ type: INTEGRATIONS_ACTIONS.SELECT_INTEGRATION, payload: id });
};

export const unselectIntegration: SelectionAction = dispatch => (id) => {
  dispatch({ type: INTEGRATIONS_ACTIONS.UNSELECT_INTEGRATION, payload: id });
};
