import styled from 'styled-components';

export const Image = styled.img`
  width: 90px;
  border-radius: 45px;
  margin-bottom: 3rem;
`;

export const Label = styled.div`
  font-size: calc(7rem/6);
  line-height: calc(4rem/3);
  color: ${props => props.theme.palette.common.dark_grey};
`;
