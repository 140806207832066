import { Checkbox } from '@salesforce/design-system-react';
import { ChangeEvent, FC } from 'react';
import { Flex } from '../../../../../../styles';

import { IntegrationCheckboxProps } from './types';

import { Image, Label } from './styles';
import { useIntegrationsApi, useIntegrationsState } from '../../../../../../context/integrations';

const IntegrationCheckbox: FC<IntegrationCheckboxProps> = ({ integration }) => {
  const { dispatch, selectIntegration, unselectIntegration } = useIntegrationsApi();
  const { selectedIntegrations } = useIntegrationsState();

  const handleCheck: (event: ChangeEvent<HTMLInputElement>, id: string) => void = (event, id) => {
    event.target.checked
      ? selectIntegration(dispatch)(id)
      : unselectIntegration(dispatch)(id);
  };

  return (
    <Flex key={integration.id} direction="column" >
      <Image src={integration.logo}/>
      <Flex>
        <Checkbox
          checked={selectedIntegrations.find(selected => selected === integration.id) !== undefined}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheck(e, integration.id)}
        />
        <Label>{integration.name}</Label>
      </Flex>
    </Flex>
  );
};

export default IntegrationCheckbox;
