import { FETCH_ACTIONS } from '../constants/action-types';

import { getAccount } from '../queries/account';

import { Account, FetchAccountAction } from '../types/account';

export const getUser: FetchAccountAction = dispatch => async (authToken) => {
  try {
    dispatch({ type: FETCH_ACTIONS.FETCH_START });
    const account = await getAccount(authToken);
    dispatch({ type: FETCH_ACTIONS.FETCH_SUCCESS, payload: account as Account });
  } catch (error: unknown) {
    dispatch({ type: FETCH_ACTIONS.FETCH_ERROR, payload: (error as Error).message });
  }
};
