import { createContext, FC, ReactNode, useReducer, useMemo, useContext } from 'react';
import { registrationActions } from '../actions';
import registrationReducer from '../reducers/registration';
import { registrationState } from '../state/registration';
import { IRegistrationApiContext, IRegistrationState } from '../types/registration';

const RegistrationStateContext = createContext<IRegistrationState>(registrationState);
const RegistrationApiContext = createContext<IRegistrationApiContext | null>(null);

export const RegistrationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(registrationReducer, registrationState);

  const api = useMemo(
    () => ({
      dispatch,
      checkTerms: registrationActions.checkTerms,
      setField: registrationActions.setField,
      registerUser: registrationActions.registerUser
    }),
    [dispatch, registrationActions]
  );

  return (
    <RegistrationStateContext.Provider value={state}>
      <RegistrationApiContext.Provider value={api}>
        {children}
      </RegistrationApiContext.Provider>
    </RegistrationStateContext.Provider>
  );
};

export const useRegistrationState: () => IRegistrationState = () => {
  const ctx = useContext(RegistrationStateContext);

  return ctx;
};

export const useRegistrationApi: () => IRegistrationApiContext = () => {
  const ctx = useContext(RegistrationApiContext);

  if (ctx === null) {
    throw new Error('RegistrationApiContext must be used within the RegistrationProvider');
  }

  return ctx;
};
