import { createContext, FC, ReactNode, useReducer, useMemo, useContext } from 'react';
import { stepsActions } from '../actions';
import stepsReducer from '../reducers/steps';
import { stepsState } from '../state/steps';
import { IStepsApiContext, IStepsState } from '../types/steps';

const StepsStateContext = createContext<IStepsState>(stepsState);
const StepsApiContext = createContext<IStepsApiContext | null>(null);

export const StepsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(stepsReducer, stepsState);

  const api = useMemo(
    () => ({
      dispatch,
      setStep: stepsActions.setStep
    }),
    [dispatch, stepsActions]
  );

  return (
    <StepsStateContext.Provider value={state}>
      <StepsApiContext.Provider value={api}>
        {children}
      </StepsApiContext.Provider>
    </StepsStateContext.Provider>
  );
};

export const useStepsState: () => IStepsState = () => {
  const ctx = useContext(StepsStateContext);

  return ctx;
};

export const useStepsApi: () => IStepsApiContext = () => {
  const ctx = useContext(StepsApiContext);

  if (ctx === null) {
    throw new Error('StepsApiContext must be used within the StepsProvider');
  }

  return ctx;
};
