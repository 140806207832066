import styled from 'styled-components';

import { Button } from '@salesforce/design-system-react';

export const Container = styled.div`
  max-width: 604px;
`;

export const Image = styled.img`
  display: block;
  margin: auto;
`;

export const Label = styled.h4`
  align-self: start;
  font-weight: 500;
  font-size: calc(10rem/6);
  line-height: calc(25rem/ 12);
  margin-top: calc(10rem/3);
  color: ${props => props.theme.palette.common.dark_grey};
`;

export const Description1 = styled.p`
  font-size: calc(7rem/6);
  line-height: 1.5rem;
  margin-top: calc(15rem/12);
`;

export const Description2 = styled.p`
  font-size: calc(7rem/6);
  line-height: 1.5rem;
  margin-top: 0.6rem;
  align-self: start;
`;

export const SButton = styled(Button)`
  display: block;
  margin: auto;
  margin-top: calc(10rem/3);
`;
