import styled from 'styled-components';

import { Combobox, Input } from '@salesforce/design-system-react';

export const Container = styled.div`
  grid-column: 1;
  grid-row: 3;
  padding-right: 2rem;
`;

export const SInput = styled(Input)`
  margin-bottom: calc(4rem / 3);
  font-size: calc(13rem / 12);
  width: 100%;
`;

export const AlignEnd = styled.div`
  margin-top: calc(16rem /3);
  align-self: end;
`;

export const AlignStart = styled.div`
  margin-top: 2rem;
  align-self: start;
`;

export const TermsLink = styled.a`
  color: ${props => props.theme.palette.common.light_blue};
`;

export const SCombobox = styled(Combobox)`
  font-size: calc(13rem / 12);
  width: 391px;
`;

export const Margin = styled.div`
  margin-bottom: calc(4rem / 3);
`;

export const GeneralError = styled.div`
  grid-column: 1;
  grid-row: 2;
  color: red;
  place-self: center;
`;
