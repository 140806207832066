import { Button } from '@salesforce/design-system-react';
import styled from 'styled-components';

export const Container = styled.div`
  grid-row: 3;
  display: flex;
  align-items: center;
`;

export const SButton = styled(Button)`
  font-size: 13px;
`;

export const UsersCount = styled.p`
  margin-left: 1rem;
  color: ${props => props.theme.palette.common.black};
`;
