import { IIntegrationsState, Integration } from '../types/integrations';

const integrations: Integration[] = [];

export const integrationsState: IIntegrationsState = {
  integrations,
  selectedIntegrations: [],
  loading: false,
  error: ''
};
