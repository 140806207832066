import { IRegistrationForm, IRegistrationState } from '../types/registration';

const registrationForm: IRegistrationForm = {
  name: '',
  surname: '',
  industry: '',
  company: '',
  position: '',
  email: '',
  password: '',
  termsAccepted: false,
  captcha: ''
};

export const registrationState: IRegistrationState = {
  form: registrationForm,
  loading: false,
  errors: []
};
