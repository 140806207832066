import { FETCH_ACTIONS, FORM_ACTIONS, REGISTRATION_ACTIONS } from '../constants/action-types';
import { IAction, IFormActionPayload } from '../types/common';
import { IRegistrationError, IRegistrationState } from '../types/registration';

const registrationReducer = (state: IRegistrationState, action: IAction): IRegistrationState => {
  switch (action.type) {
    case FETCH_ACTIONS.FETCH_START:
      return {
        ...state,
        loading: true,
        errors: []
      };
    case FETCH_ACTIONS.FETCH_SUCCESS:
      return {
        form: {
          name: '',
          surname: '',
          industry: '',
          company: '',
          position: '',
          email: '',
          password: '',
          termsAccepted: false,
          captcha: ''
        },
        loading: false,
        errors: []
      };
    case FETCH_ACTIONS.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        errors: action.payload ? (action.payload as IRegistrationError[]) : state.errors
      };
    case REGISTRATION_ACTIONS.SET_IS_CHECKED:
      return {
        ...state,
        form: {
          ...state.form,
          termsAccepted: action.payload as boolean
        }
      };
    case FORM_ACTIONS.SET_FIELD:
      // eslint-disable-next-line no-case-declarations
      const { target, value } = (action.payload as IFormActionPayload);
      return {
        ...state,
        form: {
          ...state.form,
          [target]: value
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default registrationReducer;
