import styled from 'styled-components';

export const NavbarClasses = styled.div`
    .bg_greyish_blue {
        color: white;
        background: ${props => props.theme.palette.common.greyish_blue};
        border: none;
        height: 5.5rem;
        padding: 0;
    }

    .logout_icon {
        height: 100%;
        width: auto;
        margin-right: 2.5rem;
        color: ${props => props.theme.palette.common.white};
    }

    .logout_icon > .slds-button__icon_large {
        height: calc(32rem / 12);
        width: calc(32rem / 12);
    }
`;

export const Logo = styled.p`
    padding: 2rem 2.5rem;
    font-size: 2rem;
    line-height: calc(5rem / 3);
`;
