import { FETCH_ACTIONS, FORM_ACTIONS, REGISTRATION_ACTIONS } from '../constants/action-types';

import { sendRegistrationForm } from '../queries/registration';
import { IAuthorizationForm } from '../types/authorization';

import { FormAction, SetBooleanValueAction } from '../types/common';

import { IRegistrationError, RegistrationAction } from '../types/registration';
import { authorizeUser } from './authorization';

export const checkTerms: SetBooleanValueAction = dispatch => (isChecked: boolean) => {
  dispatch({ type: REGISTRATION_ACTIONS.SET_IS_CHECKED, payload: isChecked });
};

export const setField: FormAction = dispatch => (target, value) => {
  dispatch({ type: FORM_ACTIONS.SET_FIELD, payload: { target, value } });
};

export const registerUser: RegistrationAction = dispatch => async (registrationData, authDispatch, callback = () => {}) => {
  try {
    dispatch({ type: FETCH_ACTIONS.FETCH_START });
    sendRegistrationForm(registrationData)
      .then((loginData) => {
        dispatch({ type: FETCH_ACTIONS.FETCH_SUCCESS });
        authorizeUser(authDispatch)(loginData as IAuthorizationForm, callback);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ACTIONS.FETCH_ERROR,
          payload: normalizeRegistrationErrorArray(error)
        });
      });
  } catch (error: unknown) {
    dispatch({
      type: FETCH_ACTIONS.FETCH_ERROR,
      payload: [{
        path: 'general',
        message: (error as Error).message
      }]
    });
  }
};

const normalizeRegistrationErrorArray: (error: unknown) => IRegistrationError[] = error => {
  return Array.isArray(error)
    ? error.map((element: Object) => normalizeRegistrationError(element))
    : [{ path: 'general', message: (error as Error).message }];
};

const normalizeRegistrationError: (error: Object) => IRegistrationError = error => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return Object.keys(error).find(key => key === 'path')
    ? (error as IRegistrationError)
    : { path: 'general', message: (error as Error).message };
};
