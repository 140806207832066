import { IRegistrationForm } from '../types/registration';

export const sendRegistrationForm: (data: IRegistrationForm) => Promise<unknown> = async (registrationData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL as string}/signup`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(registrationData)
      }
    );
    await response.json();
    switch (response.status) {
      case 204:
        return await Promise.resolve({ email: registrationData.email, password: registrationData.password });
      case 422:
        return await Promise.reject(response.body);
      default:
        return await Promise.reject(new Error('Error occured during registration.'));
    }
  } catch (error: unknown) {
    return await Promise.reject(error);
  }
};
