import { IAuthorizationForm, IAuthorizationState } from '../types/authorization';

export const authorizationForm: IAuthorizationForm = {
  email: '',
  password: ''
};

export const authorization: IAuthorizationState = {
  form: authorizationForm,
  authToken: '',
  refreshToken: '',
  loading: false,
  error: ''
};
