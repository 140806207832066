import { FC, useReducer, lazy, Suspense } from 'react';

import {
  IconSettings
} from '@salesforce/design-system-react';

import { ThemeProvider } from 'styled-components';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './containers/Navbar';

import Theme from './theme';

import { Home } from './components';
import { RegistrationProvider } from './context/registration';
import { StepsProvider } from './context/steps';
import { IntegrationsProvider } from './context/integrations';
import UsersList from './containers/UsersList';
import { UsersProvider } from './context/users';

const Welcome = lazy(async () => await import('./containers/Welcome'));
const Register = lazy(async () => await import('./containers/Register'));

const App: FC = () => {
  const [isOpen, toggleOpen] = useReducer((p: boolean) => !p, true);

  return (
    <IconSettings iconPath="/icons">
      <ThemeProvider theme={Theme}>
        <Navbar />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home />
              }
            />
            <Route
              path="/users"
              element={
                <Suspense>
                  <UsersProvider>
                    <UsersList />
                  </UsersProvider>
                </Suspense>
              }
            />
            <Route
              path="/login"
              element={
                <Suspense>
                  <Welcome
                    isOpen={isOpen}
                    toggleOpen={toggleOpen}
                  />
                </Suspense>
              }
            />
            <Route
              path="/forgotten-password"
              element={
                <Suspense>
                  <Welcome
                    isOpen={isOpen}
                    toggleOpen={toggleOpen}
                    isForgottenPassword
                  />
                </Suspense>
              }
            />
            <Route
              path="/register"
              element={
                <Suspense>
                  <StepsProvider>
                    <RegistrationProvider>
                      <IntegrationsProvider>
                        <Register
                          isOpen={isOpen}
                          toggleOpen={toggleOpen}
                        />
                      </IntegrationsProvider>
                    </RegistrationProvider>
                  </StepsProvider>
                </Suspense>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </IconSettings>
  );
};

export default App;
