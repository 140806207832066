import { Button } from '@salesforce/design-system-react';
import styled from 'styled-components';

export const Container = styled.section`
  grid-row: 2;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 373px 1fr 220px 13px 140px;
  width: 100%;
  align-self: end;
  height: calc(8rem / 3);
  align-items: center;
  justify-content: space-between;
`;

export const ConnectButton = styled(Button)`
  grid-column: 5;
  width: 100%;
  font-size: 13px;
`;
