export const enum FETCH_ACTIONS {
  FETCH_START = 'FETCH_START',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_ERROR = 'FETCH_ERROR',
}

export const enum FORM_ACTIONS {
  SET_FIELD = 'SET_FIELD',
}

export const enum AUTHORIZATION_ACTIONS {
  LOGOUT_USER = 'LOGOUT_USER',
}

export const enum REGISTRATION_ACTIONS {
  SET_IS_CHECKED = 'SET_IS_CHECKED',
  SET_FIELD = 'SET_FIELD',
}

export const enum STEP_ACTIONS {
  SET_STEP = 'SET_STEP',
}

export const enum INTEGRATIONS_ACTIONS {
  SELECT_INTEGRATION = 'SELECT_INTEGRATION',
  UNSELECT_INTEGRATION = 'UNSELECT_INTEGRATION',
}

export const enum USERS_ACTIONS {
  ADD_FILTER = 'ADD_FILTER',
  CLEAR_FILTER = 'CLEAR_FILTER',
  CLEAR_FILTERS = 'CLEAR_FILTERS'
}
