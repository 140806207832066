import { ButtonGroup } from '@salesforce/design-system-react';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useUsersApi, useUsersState } from '../../../../context/users';
import { Container, SButton, UsersCount } from './styles';

const FilterButtonsLevel: FC = () => {
  const { dispatch, addFilter, clearFilter } = useUsersApi();
  const { filteredUsers, users } = useUsersState();

  return (
    <Container>
      <ButtonGroup id="button-group-more-icon">
        <SButton
          label="All"
          onClick={() => {
            clearFilter(dispatch)({
              field: 'status',
              value: 'pending',
              condition: 'equal'
            });
            clearFilter(dispatch)({
              field: 'status',
              value: 'active',
              condition: 'equal'
            });
          }}
        />
        <SButton
          label="Connected"
          onClick={() => {
            clearFilter(dispatch)({
              field: 'status',
              value: 'pending',
              condition: 'equal'
            });
            addFilter(dispatch)({
              field: 'status',
              value: 'active',
              condition: 'equal'
            });
          }}
        />
        <SButton
          label="Pending"
          onClick={() => {
            clearFilter(dispatch)({
              field: 'status',
              value: 'active',
              condition: 'equal'
            });
            addFilter(dispatch)({
              field: 'status',
              value: 'pending',
              condition: 'equal'
            });
          }}
        />
      </ButtonGroup>
      <UsersCount>
        {
          !isEmpty(filteredUsers)
            ? `${filteredUsers.length} user${filteredUsers.length > 1 ? 's' : ''} found`
            : `${users.length} user${users.length > 1 ? 's' : ''} found`
        }
      </UsersCount>
    </Container>
  );
};

export default FilterButtonsLevel;
