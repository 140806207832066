import { IRegistrationForm } from '../../../../../../types/registration';

export const validateForm: (form: IRegistrationForm) => boolean = (form) => {
  const ignoreCaptcha = { ...form, captcha: 'ok' };
  return Object.values(ignoreCaptcha).map((value) => {
    switch (typeof value) {
      case 'string':
        return value !== '';
      case 'boolean':
        return value;
      default:
        return false;
    }
  }).reduce((acc, curr) => !acc ? false : curr, true);
};
