import { FETCH_ACTIONS, AUTHORIZATION_ACTIONS, FORM_ACTIONS } from '../constants/action-types';

import { sendLoginForm, sendRefreshToken } from '../queries/authorization';

import { AuthorizationAction, LogoutUserAction, RefreshTokenAction } from '../types/authorization';
import { FormAction } from '../types/common';

export const setField: FormAction = dispatch => (target, value) => {
  dispatch({ type: FORM_ACTIONS.SET_FIELD, payload: { target, value } });
};

export const authorizeUser: AuthorizationAction = dispatch => async (loginData, callback = () => {}) => {
  try {
    dispatch({ type: FETCH_ACTIONS.FETCH_START });
    const tokens = await sendLoginForm(loginData);
    dispatch({ type: FETCH_ACTIONS.FETCH_SUCCESS, payload: tokens });
    callback();
  } catch (error: unknown) {
    dispatch({ type: FETCH_ACTIONS.FETCH_ERROR, payload: (error as Error).message });
  }
};

export const logoutUser: LogoutUserAction = dispatch => () => {
  dispatch({ type: AUTHORIZATION_ACTIONS.LOGOUT_USER });
};

export const refreshToken: RefreshTokenAction = dispatch => async (refreshToken) => {
  try {
    dispatch({ type: FETCH_ACTIONS.FETCH_START });
    const newTokens = await sendRefreshToken(refreshToken);
    dispatch({ type: FETCH_ACTIONS.FETCH_SUCCESS, payload: newTokens });
  } catch (error: unknown) {
    dispatch({ type: FETCH_ACTIONS.FETCH_ERROR, payload: (error as Error).message });
  }
};
