import { createContext, FC, ReactNode, useReducer, useMemo, useContext } from 'react';

import { IIntegrationsApiContext, IIntegrationsState } from '../types/integrations';

import { integrationsActions } from '../actions';

import IntegrationsReducer from '../reducers/integrations';

import { integrationsState } from '../state/integrations';

const IntegrationsStateContext = createContext<IIntegrationsState>(integrationsState);
const IntegrationsApiContext = createContext<IIntegrationsApiContext | null>(null);

export const IntegrationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(IntegrationsReducer, integrationsState);

  const api = useMemo(
    () => ({
      dispatch,
      selectIntegration: integrationsActions.selectIntegration,
      unselectIntegration: integrationsActions.unselectIntegration,
      loadIntegrations: integrationsActions.loadIntegrations,
      sendIntegrations: integrationsActions.sendIntegrations
    }),
    [dispatch, integrationsActions]
  );

  return (
    <IntegrationsStateContext.Provider value={state}>
      <IntegrationsApiContext.Provider value={api}>
        {children}
      </IntegrationsApiContext.Provider>
    </IntegrationsStateContext.Provider>
  );
};

export const useIntegrationsState: () => IIntegrationsState = () => {
  const ctx = useContext(IntegrationsStateContext);

  return ctx;
};

export const useIntegrationsApi: () => IIntegrationsApiContext = () => {
  const ctx = useContext(IntegrationsApiContext);

  if (ctx === null) {
    throw new Error('Integrations Api Context must be used within the Integrations Provider');
  }

  return ctx;
};
