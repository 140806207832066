import { FC } from 'react';
import {
  Button,
  GlobalNavigationBar,
  GlobalNavigationBarRegion
} from '@salesforce/design-system-react';

import { useAuthorizationApi, useAuthorizationState } from '../../context/authorization';

import {
  NavbarClasses,
  Logo
} from './styles';

const Navbar: FC = () => {
  const { authToken } = useAuthorizationState();
  const { dispatch, logoutUser } = useAuthorizationApi();

  return (
    <NavbarClasses>
      <GlobalNavigationBar className="bg_greyish_blue">
        <GlobalNavigationBarRegion region="primary">
          <Logo>Unveel</Logo>
        </GlobalNavigationBarRegion>
        {/* <GlobalNavigationBarRegion region="secondary">
          MENU
        </GlobalNavigationBarRegion> */}
        <GlobalNavigationBarRegion region="tertiary">
          {// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            authToken && <Button
              className="logout_icon"
              onClick={() => {
                logoutUser(dispatch)();
              }}
              iconName="outcome"
              iconCategory="standard"
              iconSize="large"
              variant="icon"
            />
          }
        </GlobalNavigationBarRegion>
      </GlobalNavigationBar>
    </NavbarClasses>
  );
};

export default Navbar;
