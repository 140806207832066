import { FC } from 'react';

import {
  Modal
} from '@salesforce/design-system-react';

import { WelcomeModalProps } from './types';

const WelcomeModal: FC<WelcomeModalProps> = ({ isOpen, toggleOpen, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleOpen}
      size="large"
    >
      {children}
    </Modal>
  );
};

export default WelcomeModal;
