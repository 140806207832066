import { IUserState } from '../types/users';

export const usersState: IUserState = {
  users: [{
    id: 'very-active-user-uuid',
    name: 'Joe Doe',
    avatar: 'https://example.com/img/avatar.png',
    platforms: [
      'glovo',
      'uber',
      'wolt'
    ],
    lastWorkdayIncome: [
      {
        value: 1342.14,
        currency: 'PLN'
      }
    ],
    totalIncome: [
      {
        value: 1354.48,
        currency: 'PLN'
      },
      {
        value: 55.55,
        currency: 'USD'
      }
    ],
    addedAt: {
      date: '2022-11-23 13:09:09.000000',
      timezone_type: 3,
      timezone: 'UTC'
    },
    lastSyncAt: {
      date: '2022-10-15 00:20:00.000000',
      timezone_type: 3,
      timezone: 'UTC'
    },
    gigsOfLastWorkday: 1,
    status: 'active'
  },
  {
    id: 'regular-user-uuid',
    name: 'Regular User',
    avatar: null,
    platforms: [
      'glovo',
      'uber'
    ],
    lastWorkdayIncome: [],
    totalIncome: [
      {
        value: 66.66,
        currency: 'USD'
      }
    ],
    addedAt: {
      date: '2022-11-23 13:09:09.000000',
      timezone_type: 3,
      timezone: 'UTC'
    },
    lastSyncAt: null,
    gigsOfLastWorkday: 0,
    status: 'pending'
  }
  ],
  filters: [],
  filteredUsers: [],
  loading: false,
  error: ''
};
