import { ReactNode } from 'react';

import { DataTableColumn } from '@salesforce/design-system-react';
import {
  DailyIncomeCell,
  DateCell,
  LastSyncCell,
  NameCell,
  PlatformsCell,
  TotalIncomeCell,
  StatusCell
} from './Cells';

export const columns: ReactNode[] = [
  <DataTableColumn
    key="name"
    label="Name"
    property="name">
    <NameCell />
  </DataTableColumn>,
  <DataTableColumn
    key="platforms"
    label="Platform"
    property="platforms">
    <PlatformsCell />
  </DataTableColumn>,
  <DataTableColumn
    key="lastWorkdayIncome"
    label="Daily income"
    property="lastWorkdayIncome">
    <DailyIncomeCell />
  </DataTableColumn>,
  <DataTableColumn
    key="totalIncome"
    label="Total income"
    property="totalIncome">
    <TotalIncomeCell />
  </DataTableColumn>,
  <DataTableColumn
    key="addedAt"
    label="User added"
    property="addedAt">
    <DateCell />
  </DataTableColumn>,
  <DataTableColumn
    key="lastSyncAt"
    label="Last synced"
    property="lastSyncAt">
    <LastSyncCell />
  </DataTableColumn>,
  <DataTableColumn
    key="gigsOfLastWorkday"
    label="Gigs Today"
    property="gigsOfLastWorkday"
  />,
  <DataTableColumn
    key="status"
    label="Status"
    property="status">
    <StatusCell />
  </DataTableColumn>
];
