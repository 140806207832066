import styled from 'styled-components';

export const Container = styled.div`
    grid-column: 2;
    grid-row: 3;
`;

export const Title = styled.h3`
    font-weight: 500;
    font-size: calc(5rem / 3);
    align-self: flex-start;
    color: ${props => props.theme.palette.common.dark_grey};
    padding-bottom: calc(5rem /3);
`;

export const Tile = styled.div`
    align-self: flex-start;
    font-size: calc(7rem /6);
    line-height: 1.5rem;
    max-width: 360px;
    padding-bottom: calc(5rem /3);
`;

export const Question = styled.h4`
    color: ${props => props.theme.palette.common.light_grey};
`;

export const Answer = styled.p`
    color: ${props => props.theme.palette.common.black};
`;
