import { FC, ReactNode } from 'react';

import { Avatar, DataTableCell } from '@salesforce/design-system-react';
import { User } from '../../../../../../types/users';
import { Container, Name } from './styles';

interface CustomDataTableCellProps {
  children?: ReactNode
  item?: User
}

const CustomDataTableCell: FC<CustomDataTableCellProps> = ({ children, item, ...props }) => {
  return (
    <DataTableCell {...props}>
      <Container>
        <Avatar
          variant='user'
          imgSrc={item?.avatar}
          imgAlt={item?.name}
          assistiveText={{ icon: 'user avatar' }}
          label={item?.name}
        />
        <Name>{item?.name}</Name>
      </Container>
    </DataTableCell>
  );
};

CustomDataTableCell.displayName = DataTableCell.displayName;

export {
  CustomDataTableCell as NameCell
};
