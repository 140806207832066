import { FC } from 'react';
import { NameSearchBar, PlatformSearchBar } from './components';
import { ConnectButton, Container } from './styles';

const SearchBarLevel: FC = () => {
  return (
    <Container>
      <NameSearchBar />
      <PlatformSearchBar />
      <ConnectButton
        variant="brand"
        title="Connect new user"
        label="Connect new user"
        onClick={() => { console.log('connect new user clicked'); }}
      />
    </Container>
  );
};

export default SearchBarLevel;
