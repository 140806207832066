import { AUTHORIZATION_ACTIONS, FETCH_ACTIONS, FORM_ACTIONS } from '../constants/action-types';
import { IAuthorizationState, ITokenPayload } from '../types/authorization';

import { IAction, IFormActionPayload } from '../types/common';

const authorizationReducer = (state: IAuthorizationState, action: IAction): IAuthorizationState => {
  switch (action.type) {
    case FETCH_ACTIONS.FETCH_START:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case FETCH_ACTIONS.FETCH_SUCCESS:
      return {
        form: {
          email: '',
          password: ''
        },
        authToken: (action.payload as ITokenPayload).authToken,
        refreshToken: (action.payload as ITokenPayload).refreshToken,
        loading: false,
        error: ''
      };
    case FETCH_ACTIONS.FETCH_ERROR:
      return {
        ...state,
        authToken: '',
        refreshToken: '',
        loading: false,
        error: action.payload as string
      };
    case FORM_ACTIONS.SET_FIELD:
      // eslint-disable-next-line no-case-declarations
      const { target, value } = (action.payload as IFormActionPayload);
      return {
        ...state,
        form: {
          ...state.form,
          [target]: value
        },
        error: ''
      };
    case AUTHORIZATION_ACTIONS.LOGOUT_USER:
      return {
        form: {
          email: '',
          password: ''
        },
        authToken: '',
        refreshToken: '',
        loading: false,
        error: ''
      };
    default:
      return {
        ...state
      };
  }
};

export default authorizationReducer;
