import { STEP_ACTIONS } from '../constants/action-types';

import { IStepAction, IStepsState } from '../types/steps';

const stepsReducer = (state: IStepsState, action: IStepAction): IStepsState => {
  switch (action.type) {
    case STEP_ACTIONS.SET_STEP:
      return {
        ...state,
        completedSteps: state.steps.slice(0, action.payload.id),
        disabledSteps: action.payload.id < state.steps.length
          ? state.steps.slice(action.payload.id + 2, state.steps.length)
          : [],
        selectedStep: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default stepsReducer;
