import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2.5rem calc(16rem/ 3) auto;
  justify-items: center;
  align-items: start;
  padding: 0 2rem;
`;

export const Label = styled.h2`
  grid-column: 1;
  grid-row: 1;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  width: 390px;
`;
