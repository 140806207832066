import { FETCH_ACTIONS, USERS_ACTIONS } from '../constants/action-types';
import { getUsers } from '../queries/users';
import { FilterAction, LoadUsersAction, UsersAction } from '../types/users';

export const loadUsers: LoadUsersAction = dispatch => async (authToken) => {
  try {
    dispatch({ type: FETCH_ACTIONS.FETCH_START });
    const users = await getUsers(authToken);
    dispatch({ type: FETCH_ACTIONS.FETCH_SUCCESS, payload: users });
  } catch (error: unknown) {
    dispatch({ type: FETCH_ACTIONS.FETCH_ERROR, payload: (error as Error).message });
  }
};

export const addFilter: FilterAction = dispatch => (filter) => {
  dispatch({ type: USERS_ACTIONS.ADD_FILTER, payload: filter });
};

export const clearFilter: FilterAction = dispatch => (filter) => {
  dispatch({ type: USERS_ACTIONS.CLEAR_FILTER, payload: filter });
};

export const clearFilters: UsersAction = dispatch => () => {
  dispatch({ type: USERS_ACTIONS.CLEAR_FILTERS });
};
